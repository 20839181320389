import { createContext, useContext, useEffect, useState } from "react";

import { ProductDetailProps } from "assets/Content";
import { getAllProducts } from "firestore/products";

interface FirestoreContextType {
  products: ProductDetailProps[];
  loading: boolean;
}
const FirestoreContext = createContext<FirestoreContextType | undefined>(
  undefined
);

export const FirestoreProvider = ({ children }: any) => {
  const [products, setProducts] = useState<ProductDetailProps[]>([]);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    const fetchData = async () => {
      const items = await getAllProducts();
      setProducts(items);
      setLoading(false);
    };
    fetchData();
  }, []);
  return (
    <FirestoreContext.Provider value={{ products, loading }}>
      {children}
    </FirestoreContext.Provider>
  );
};
export const useFirestore = (): FirestoreContextType => {
  const context = useContext(FirestoreContext);
  if (!context) {
    throw new Error("useFirestore must be used within a FirestoreProvider");
  }
  return context;
};
