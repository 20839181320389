import { ChangeEventHandler } from "react";
import { TextField } from "@mui/material";
import { isValidLetters } from "utils/helper";

export const StringField = ({
  value = "",
  label,
  labelClass,
  handleOnChange,
  className,
  handleError,
  required,
}: {
  value: string;
  label?: string;
  labelClass?: string;
  handleOnChange?: ChangeEventHandler;
  className?: string;
  disabled?: boolean;
  handleError?: boolean;
  required?: boolean;
}) => {
  return (
    <div className="inline-flex w-full my-1">
      {label && (
        <span className={labelClass ?? "w-1/3 my-auto text-left"}>{label}</span>
      )}
      <TextField
        className={className ?? "w-2/3 h-full"}
        type="string"
        size="small"
        value={value}
        onChange={handleOnChange}
        error={
          handleError === undefined
            ? !isValidLetters(value) && value.length > 0
            : handleError
        }
      />
    </div>
  );
};

export default StringField;
