import { getPriceForQty, getUnitPrice } from "paypal/paypal";

import emailjs from "@emailjs/browser";
import { getShippingInfo } from "firestore/shipping";

export const sendOrderConfirmEmail = async (order: any) => {
  const shippingCountries = await getShippingInfo();
  const { shipping, items, amount, custom_id } = order?.purchase_units[0];
  const email = custom_id.split("&")[0];
  const phoneNumber = custom_id.split("&")[1];
  const total = amount?.value;
  const tax = amount?.breakdown?.tax_total?.value;
  const subtotal = (
    Number(amount?.breakdown?.item_total?.value) + Number(tax)
  ).toFixed(2);
  const shippingCost = amount?.breakdown?.shipping?.value;
  const { address, name } = shipping;
  const {
    address_line_1,
    address_line_2,
    admin_area_1,
    admin_area_2,
    postal_code,
    country_code,
  } = address;
  const { country } = shippingCountries.find(
    ({ countryCode }) => countryCode == country_code
  );
  const { payer } = order;
  const templateParams = {
    message: `
    <html>
    <head>
      <style type="text/css">
        body {
          font-family: arial, sans-serif;
          border-collapse: collapse;
          width: 100%;
        }
        td,
        th {
          text-align: left;
          padding: 8px;
        }
        span {
            margin: 2px 0;
            width: 100%;
            display: block;
        }
      </style>
    </head>
    <body>
      <div style="width: 80%; margin: auto">
        <h3 style="text-align: center">Thank you for your Order</h3>
        <div style="display:flex; width:100%; margin: 12px 0px">
            <span>Order Date: ${order.create_time.split("T")[0]}
            </span>
            <span>${email}</span>
            <span>${phoneNumber}</span>
            <span>Order Number: ${order.id}</span>
        </div>
        <div style="display: flex; width:100%;">
            <div style="float: left; width: 100%;">
                <span>Shipping Address</span>
                <span>${name.full_name}</span>
                <span>${address_line_1}</span>
                ${address_line_2 ? `<span>${address_line_2}</span>` : ""}
                <span>${admin_area_2}, ${admin_area_1} ${postal_code}</span>
                <span>${country}</span>
              </div>
              <div style="float: right; text-align:right; width: 100%;">
                  <span>Order Summary</span>
                  <span>Item(s) Subtotal: ${subtotal + "\u20AC"}</span>
                  <span>Shipping: ${
                    shippingCost == "0" ? "free" : shippingCost + "\u20AC"
                  }</span>
                  <span style="font-weight: bold;">Total: ${
                    total + "\u20AC"
                  }</span>
                  <span style="font-size: 12px;">Tax (VAT 19%) Included</span>
              </div>
          </div>
      <table style="border-collapse: collapse; width: 100%; margin-top: 5vh; margin: auto;">
        <tr style="border-bottom: 3px solid #dddddd;">
          <th>Proudct</th>
          <th>Quantity</th>
          <th>Unit Price</th>
          <th>Price</th>
        </tr>
        ${
          items &&
          items
            .map(
              (item: any) =>
                `<tr style="margin:4px; width:100%;">
                    <td>${item.name}</td>
                    <td>${item.quantity}</td>
                    <td>${getUnitPrice(item)}</td>
                    <td>${getPriceForQty(item)}</td>
                </tr>`
            )
            .join("")
        }
      </table>
    </body>
  </html>
  `,
    //email: `${email}; ischo@sojuhalle.com;`,
    email: `${payer.email_address}; ${process.env.REACT_APP_ORDER_EMAILS}`,
  };
  emailjs.send(
    "sojuhalle_info",
    "template_order_received",
    templateParams,
    "VrtwxuHHFbnrp3Gqn"
  );

  //emailjs.send(
  //  "sojuhalle_info",
  //  "template_order_received",
  //  templateParams,
  //  "VrtwxuHHFbnrp3Gqn"
  //);
};
