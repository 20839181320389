import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  Checkbox,
  InputAdornment,
  ListSubheader,
  MenuItem,
  Modal,
  Select,
  TextField,
} from "@mui/material";
import { DataGrid, GridColDef, GridSearchIcon } from "@mui/x-data-grid";
import {
  createBusinessOrder,
  createShipping,
  getAllOrders,
  updateStatus,
  updateWeights,
  voidShipment,
} from "firestore/orders";
import { useMemo, useRef, useState } from "react";

import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DatePicker from "react-datepicker";
import { ExcelDownloadButton } from "components/ExcelDownloadButton";
import NumberField from "components/Inputs/NumberField";
import { Order } from "types/order";
import PDFDocument from "components/PDFDocument";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { ProductDetailProps } from "assets/Content";
import { Timestamp } from "firebase/firestore";
import { getFullAddress } from "utils/helper";

export const Orders = ({
  allOrders,
  allCustomers,
  allProducts,
  availableLocations,
  setAllOrders,
}: {
  allOrders: any[];
  allCustomers: any[];
  allProducts: any[];
  availableLocations: any[];
  setAllOrders: any;
}) => {
  const [createShipment, setCreateShipment] = useState(false);
  const [isSample, setIsSample] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState<any>("");
  const [shippedFrom, setShippedFrom] = useState<string>("Hamburg");
  const [selectedProducts, setSelectedProducts] = useState<any[]>([]);
  const [shippingCost, setShippingCost] = useState<number | "">(10);
  const [discount, setDiscount] = useState<number | "">(0);
  const [numberOfPackages, setNumberOfPackages] = useState<number | "">(1);
  const [weights, setWeights] = useState<number[]>([]);
  const [deliveryDate, setDeliveryDate] = useState<Date>();
  const [dateToUpdate, setDateToUpdate] = useState<Date>(new Date());
  const [orderStatus, setOrderStatus] = useState<string>("");
  const [openModal, setOpenModal] = useState<number>(-1);
  const [openWeightModal, setOpenWeightModal] = useState<string>("");
  const [searchText, setSearchText] = useState<string>("");
  const textFieldRef = useRef<HTMLInputElement>(null);

  const displayedOptions = useMemo(
    () =>
      allCustomers.filter(
        (customer) =>
          customer?.name?.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1 ||
          customer?.shortName?.toLowerCase().indexOf(searchText.toLowerCase()) >
            -1
      ),
    [searchText]
  );

  const getDateString = (dateTimestamp: Timestamp) => {
    if (!dateTimestamp) return "";
    const date = dateTimestamp?.toDate().toISOString().split("T")[0];
    return date.replaceAll("-", "/");
  };

  const isValidOrder = (order: any) => {
    const isValidCustomer =
      order?.customerInfo?.name &&
      order?.customerInfo?.email &&
      //order?.customerInfo?.phoneNumber &&
      order?.customerInfo?.address;
    const isValidItems = order?.items?.length > 0;
    const isValidWeight = !order?.createShipment || order?.weights.length > 0;
    return isValidCustomer && isValidItems && isValidWeight;
  };

  const columns: GridColDef[] = [
    {
      field: "orderId",
      headerName: "ID",
      minWidth: 40,
    },
    {
      field: "created",
      headerName: "Created",
      minWidth: 100,
      renderCell: (params) => getDateString(params.row.created),
    },
    {
      field: "lastUpdated",
      headerName: "Shipped / Delivered",
      minWidth: 100,
      renderCell: (params) => getDateString(params.row.lastUpdated),
    },
    {
      field: "status",
      headerName: "Status",
      minWidth: 200,
      renderCell: (params) =>
        params.row.completedDate ? (
          getDateString(params.row.completedDate) + " (Completed)"
        ) : (
          <Select
            className="w-[8rem] h-[2rem]"
            value={allOrders.find(({ id }) => params.id == id)?.status}
            onChange={(e: any) => {
              const index = allOrders.findIndex(({ id }) => params.id == id);
              setOrderStatus(e.target.value);
              if (
                e.target.value == "Completed" ||
                e.target.value == "Delivered" ||
                e.target.value == "Shipped"
              )
                setOpenModal(index);
              //Shipped not working yet
              // status -> ordered, delivered, complete
              // if delivered ask delivery date
              // update FS here
            }}
          >
            <MenuItem value={"Ordered"}>Ordered</MenuItem>
            <MenuItem value={"Shipped"}>Shipped</MenuItem>
            <MenuItem value={"Delivered"}>Delivered</MenuItem>
            <MenuItem value={"Completed"}>Completed</MenuItem>
          </Select>
        ),
    },
    {
      field: "type",
      headerName: "Type",
      width: 100,
      minWidth: 100,
      renderCell: (params) =>
        allOrders.find(({ id }) => params?.id == id).isSample
          ? "Sample"
          : params.row.type,
    },
    { field: "customerName", headerName: "Name", minWidth: 150 },
    {
      field: "customerAddress",
      headerName: "Address",
      minWidth: 250,
      sortable: false,
    },
    {
      field: "customerPhoneNumber",
      headerName: "Phone Number",
      minWidth: 120,
      sortable: false,
    },
    { field: "items", headerName: "Items", minWidth: 400, sortable: false },
    { field: "amount", headerName: "Amount", minWidth: 100 },
    {
      field: "invoice",
      headerName: "Invoice",
      minWidth: 100,
      sortable: false,
      renderCell: (params) =>
        !allOrders.find(({ id }) => params?.id == id).isSample &&
        params.row.type == "B2B" && (
          <PDFDownloadLink
            key={params.id}
            target="_blank"
            document={
              <PDFDocument
                order={allOrders.find(({ id }) => params?.id == id)}
                key={params.id}
              />
            }
          >
            Download
          </PDFDownloadLink>
        ),
    },
    {
      field: "shippingLabel",
      headerName: "ShippingLabel",
      minWidth: 100,
      renderCell: (params) => {
        const order = allOrders.find(({ id }) => params?.id == id);
        const trackingIds = order?.trackingIds ?? [];
        if (trackingIds.length > 0) {
          return trackingIds.map((trackingId: string) => (
            <a
              key={trackingId}
              className="flex text-blue-500"
              href={`https://www.ups.com/track?track=yes&trackNums=${trackingId}`}
              target="_blank"
            >
              {trackingId}
            </a>
          ));
        } else if (order?.status == "Ordered") {
          return (
            <button
              onClick={() => {
                if (order?.weights.length > 0) {
                  createShipping(order.id);
                } else {
                  setOpenWeightModal(order.id);
                }
              }}
            >
              Create
            </button>
          );
        } else {
          return <span></span>;
        }
      },
    },
    {
      field: "void",
      headerName: "Void",
      minWidth: 100,
      renderCell: (params) => {
        const order = allOrders.find(({ id }) => params?.id == id);
        if (order?.trackingIds?.length > 0)
          return (
            <button
              className="px-2 py-1 my-2 text-sm rounded-md w-fit h-2/3 bg-tertiary text-primary hover:opacity-50"
              onClick={() => {
                voidShipment(order.id);
                allOrders[
                  allOrders.findIndex(({ id }) => id === order.id)
                ].trackingIds = undefined;
                setAllOrders([...allOrders]);
                alert("Shipping Voided");
              }}
            >
              Void
            </button>
          );
        else return <span></span>;
      },
    },
  ];

  const rows =
    allOrders.length > 0
      ? allOrders.map((order) => ({
          id: order.id,
          orderId: order.orderId,
          created: order.created,
          lastUpdated: order.deliveryDate ?? order.shippedDate,
          completedDate: order.completedDate,
          status: order?.status ?? "",
          type: order?.B2B ? "B2B" : "B2C",
          customerName: order.customerInfo.name,
          customerAddress: getFullAddress(order.customerInfo.address),
          customerPhoneNumber: order.customerInfo.phoneNumber,
          items: order.items
            .map((item: any) => `${item.name} (${item.quantity})`)
            .join(", "),
          amount: order.amount?.value,
        }))
      : [];

  const rowsForExcel = allOrders.map((order: Order) => {
    const {
      orderId,
      isSample,
      B2B,
      created,
      deliveryDate,
      completedDate,
      items,
      status,
      customerInfo,
      amount,
    } = order;
    const type = isSample ? "sample" : B2B ? "B2B" : "B2C";

    const allItemsQty = allProducts.map((product: ProductDetailProps) => {
      const item = items.find((item) => item?.name == product.name);
      if (item) {
        const quantity = item?.quantity ?? 0;
        const price =
          Number(item?.tax?.value ?? 0) + Number(item?.unit_amount?.value ?? 0);
        return {
          [`${product.name} Qty`]: quantity,
        };
      } else {
        return {
          [`${product.name} Qty`]: "",
        };
      }
    });

    const allItemsPrice = allProducts.map((product: ProductDetailProps) => {
      const item = items.find((item) => item?.name == product.name);
      if (item) {
        const quantity = item?.quantity ?? 0;
        const price =
          Number(item?.tax?.value ?? 0) + Number(item?.unit_amount?.value ?? 0);
        return {
          [`${product.name} Price`]: price,
        };
      } else {
        return {
          [`${product.name} Price`]: "",
        };
      }
    });

    return {
      ID: orderId,
      Type: type,
      Ordered: created?.toDate().toISOString().split("T")[0] ?? "",
      Status: status,
      Customer: customerInfo.name,
      Completed: completedDate?.toDate().toISOString().split("T")[0] ?? "",
      Delivered: deliveryDate?.toDate().toISOString().split("T")[0] ?? "",
      "Total Amount": Number(amount?.value ?? 0),
      ...Object.assign({}, ...allItemsQty),
      ...Object.assign({}, ...allItemsPrice),
    };
  });

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <Modal
        open={openModal >= 0}
        //onClose={() => setOpenModal(-1)}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-5/6 max-w-lg m-auto bg-white rounded-lg md:w-1/2 h-60"
      >
        <div className="w-full bg-white rounded-lg h-fit">
          <div className="mx-auto text-center h-fit">
            <h3 className="inline-block w-full mt-8 text-3xl text-center md:text-4xl font-akrobat">
              Choose {orderStatus} Date
            </h3>
            <div className="flex-row">
              <div className="my-4">
                <DatePicker
                  selected={dateToUpdate}
                  onChange={(date: Date) => {
                    setDateToUpdate(date);
                  }}
                  className="flex px-2 border-2 rounded-md"
                />
              </div>
              <div className="inline-flex justify-center w-full mx-2 my-4">
                <button
                  onClick={() => {
                    updateStatus(
                      allOrders[openModal].id,
                      orderStatus,
                      Timestamp.fromDate(dateToUpdate)
                    ).then(() => {
                      getAllOrders().then((res) => setAllOrders([...res]));
                    });
                    setOpenModal(-1);
                  }}
                  className="w-24 h-8 m-auto mx-2 text-white bg-black rounded-lg px-auto py-auto text-md"
                >
                  Update
                </button>
                <button
                  onClick={() => {
                    setOpenModal(-1);
                  }}
                  className="w-24 h-8 m-auto mx-2 text-white bg-black rounded-lg px-auto py-auto text-md"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <Modal
        open={openWeightModal.length > 0}
        onClose={() => setOpenWeightModal("")}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-5/6 m-auto bg-white rounded-lg h-[30rem] md:w-2/3"
      >
        <div className="w-full h-full bg-white rounded-lg">
          <h3 className="inline-block w-full mt-8 text-3xl text-center md:text-4xl font-akrobat">
            Enter Number and Weights of Package(s)
          </h3>
          <div className="flex-row w-full mx-auto my-4 h-fit">
            <div className="flex w-3/4 mx-auto">
              <span className="min-w-[10rem] mr-2">Number of Packages:</span>
              <div className="flex my-auto border h-[2rem] min-w-[7rem]">
                <button onClick={() => setNumberOfPackages("")}>
                  <NumberField
                    className="min-w-42 text-center max-w-[10vh] max-h-fit"
                    value={numberOfPackages?.toString() ?? ""}
                    handleOnChange={(e: any) => {
                      setNumberOfPackages(
                        Number(e.target.value == "" ? 0 : e.target.value)
                      );
                    }}
                    alignText
                  />
                </button>
              </div>
            </div>
            <div className="flex w-3/4 mx-auto h-fit">
              <span className="min-w-[10rem]">Weight:</span>
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 m-auto min-h-[2rem] min-w-[7rem] ml-2 gap-1">
                {Array(numberOfPackages)
                  .fill(0)
                  .map((e, i) => (
                    <div key={i}>
                      <button
                        onClick={(e) => {
                          weights[i] = 0;
                          setWeights([...weights]);
                        }}
                        className="my-1 border"
                      >
                        <NumberField
                          className="min-w-42 text-center max-w-[8vh] max-h-fit"
                          value={weights[i]?.toString() ?? "0"}
                          handleOnChange={(e: any) => {
                            weights[i] = Number(e.target.value);
                            weights.splice(Number(numberOfPackages));
                            if (Number(numberOfPackages) > weights.length) {
                              setWeights([
                                ...weights,
                                ...Array(
                                  Number(numberOfPackages) - weights.length
                                ).fill(0),
                              ]);
                            } else {
                              setWeights([...weights]);
                            }
                          }}
                        />
                        <span className="my-auto mr-2">{"kg"}</span>
                      </button>
                    </div>
                  ))}
              </div>
            </div>
            <div className="absolute bottom-0 flex justify-center w-full my-4">
              <button
                onClick={() => {
                  createShipping(openWeightModal);
                  updateWeights(openWeightModal, weights);
                  setOpenWeightModal("");
                  setWeights([]);
                  setNumberOfPackages(1);
                }}
                className="w-24 h-8 m-auto mx-2 text-white bg-black rounded-lg px-auto py-auto text-md"
              >
                Update
              </button>
              <button
                onClick={() => {
                  setOpenWeightModal("");
                  setWeights([]);
                  setNumberOfPackages(1);
                }}
                className="w-24 h-8 m-auto mx-2 text-white bg-black rounded-lg px-auto py-auto text-md"
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Orders</h3>
        <div className="flex w-full mx-12">
          <Accordion className="w-11/12">
            <AccordionSummary expandIcon={<ArrowDropDownIcon />}>
              <div className="text-seconadry">Create Order</div>
            </AccordionSummary>
            <AccordionDetails>
              <div className="w-full pt-4 border-t-2">
                <div className="grid grid-cols-3">
                  <div className="col-span-2">
                    <div className="inline-flex w-full m-auto mt-2">
                      <span className="my-auto mr-2 w-[4rem] text-left">
                        Customer
                      </span>
                      <Select
                        className="w-[15rem] h-[2rem]"
                        label="Customer"
                        value={selectedCustomer}
                        onChange={(e: any) => {
                          setSelectedCustomer(e.target.value);
                        }}
                        onClose={() => setSearchText("")}
                        MenuProps={{
                          autoFocus: false,
                          TransitionProps: {
                            onEntered: () => {
                              if (textFieldRef.current) {
                                textFieldRef.current.focus();
                              }
                            },
                          },
                        }}
                      >
                        <ListSubheader>
                          <TextField
                            inputRef={textFieldRef}
                            size="small"
                            placeholder="Search"
                            fullWidth
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <GridSearchIcon />
                                </InputAdornment>
                              ),
                            }}
                            onChange={(e) => setSearchText(e.target.value)}
                            onKeyDown={(e) => {
                              if (textFieldRef.current) {
                                textFieldRef.current.focus();
                              }
                              if (e.key !== "Escape") {
                                e.stopPropagation();
                              }
                            }}
                          />
                        </ListSubheader>
                        {displayedOptions.map((customer, i) => (
                          <MenuItem
                            className="w-[20rem]"
                            key={i}
                            value={customer}
                          >
                            {customer.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="inline-flex w-full m-auto mt-2">
                      <span className="my-auto mr-2 w-[4rem] text-left">
                        Shipped From
                      </span>
                      <Select
                        className="w-[15rem] h-[2rem]"
                        label="Shipped From"
                        value={shippedFrom}
                        onChange={(e: any) => {
                          setShippedFrom(e.target.value);
                        }}
                        placeholder="Shipped From"
                      >
                        {availableLocations.map(({ name }) => (
                          <MenuItem key={name} value={name}>
                            {name}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                    <div className="inline-flex w-full m-auto">
                      <Checkbox
                        defaultChecked={false}
                        onChange={() => {
                          setIsSample(!isSample);
                          if (isSample) setCreateShipment(false);
                        }}
                      />
                      <div className="my-auto ">
                        <span>Sample</span>
                      </div>
                      {!isSample && (
                        <div className="inline-flex w-full">
                          <Checkbox
                            defaultChecked={false}
                            onChange={() => setCreateShipment(!createShipment)}
                          />
                          <div className="my-auto ">
                            <span>Create Shipment</span>
                          </div>

                          <div className="inline-flex">
                            <span className="my-auto ml-4 mr-2">
                              Shipping cost:{" "}
                            </span>
                            <div className="inline-flex min-w-[7rem] m-auto border h-[2rem]">
                              <button onClick={() => setShippingCost("")}>
                                <span className="mx-1 my-auto">{"\u20AC"}</span>
                                <NumberField
                                  className="min-w-42 text-center max-w-[10vh] max-h-fit"
                                  value={shippingCost}
                                  handleOnChange={(e: any) => {
                                    setShippingCost(
                                      Number(Number(e.target.value).toFixed(2))
                                    );
                                  }}
                                  alignText
                                />
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <div className="w-full space-y-2">
                      {Array.from(
                        Array(selectedProducts.length + 1).keys()
                      ).map((index) => (
                        <div key={index} className="inline-flex w-full">
                          <span className="my-auto mr-2 w-[4rem] text-left">
                            Item {index}
                          </span>
                          <Select
                            label="Product"
                            className="w-[15rem] h-[2rem] my-auto"
                            key={index}
                            defaultValue={""}
                            onChange={(e) => {
                              if (selectedProducts.includes(e.target.value)) {
                                alert("Duplicate Product");
                              } else {
                                if (index == selectedProducts.length)
                                  selectedProducts.push(e.target.value);
                                else selectedProducts[index] = e.target.value;
                                const businessPrice =
                                  allProducts.find(
                                    ({ name }) =>
                                      name == (e.target.value as any)?.name
                                  )?.businessPrice ?? "";
                                selectedProducts[index].qty = 1;
                                selectedProducts[index].businessPrice =
                                  businessPrice;
                                setSelectedProducts([...selectedProducts]);
                              }
                            }}
                          >
                            {allProducts
                              .sort(
                                (a, b) =>
                                  (a?.sort?.admin ?? 9999) -
                                  (b?.sort?.admin ?? 9999)
                              )
                              .map((product) => (
                                <MenuItem key={product.id} value={product}>
                                  {product?.name}
                                </MenuItem>
                              ))}
                          </Select>
                          {selectedProducts[index] && (
                            <div className="flex">
                              <div className="inline-flex w-[7rem] m-auto ml-4 border h-[2rem]">
                                <button
                                  className="w-6 m-auto text-xl text-black rounded-md"
                                  onClick={() => {
                                    if (selectedProducts[index].qty) {
                                      selectedProducts[index].qty -= 1;
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }
                                  }}
                                >
                                  -
                                </button>
                                <button
                                  onClick={() => {
                                    selectedProducts[index].qty = "";
                                    setSelectedProducts([...selectedProducts]);
                                  }}
                                >
                                  <NumberField
                                    className="w-36 text-center max-w-[6vh] max-h-fit"
                                    value={selectedProducts[index]?.qty ?? ""}
                                    handleOnChange={(e: any) => {
                                      selectedProducts[index].qty = Number(
                                        e.target.value
                                      );
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }}
                                    alignText
                                  />
                                </button>
                                <button
                                  className="w-6 m-auto text-xl text-black rounded-md"
                                  onClick={() => {
                                    selectedProducts[index].qty += 1;
                                    setSelectedProducts([...selectedProducts]);
                                  }}
                                >
                                  +
                                </button>
                              </div>
                              <div className="min-w-[7rem] inline-flex w-42 m-auto ml-2 border h-[2rem]">
                                <button
                                  onClick={() => {
                                    selectedProducts[index].businessPrice = "";
                                    setSelectedProducts([...selectedProducts]);
                                  }}
                                >
                                  <span className="mx-1 my-auto">
                                    {"\u20AC"}
                                  </span>
                                  <NumberField
                                    className="w-48 text-center max-w-[10vh] max-h-fit"
                                    value={
                                      selectedProducts[index]?.businessPrice ??
                                      ""
                                    }
                                    handleOnChange={(e: any) => {
                                      selectedProducts[index].businessPrice =
                                        Number(
                                          Number(e.target.value).toFixed(2)
                                        );
                                      setSelectedProducts([
                                        ...selectedProducts,
                                      ]);
                                    }}
                                    alignText
                                  />
                                </button>
                              </div>
                              <span className="ml-2">
                                {"\u20AC"}
                                {Number(
                                  selectedProducts[index].qty *
                                    selectedProducts[index].businessPrice
                                ).toFixed(2)}
                              </span>
                            </div>
                          )}
                        </div>
                      ))}
                    </div>
                  </div>
                  <div className="col-span-1">
                    <div className="flex">
                      <div className="w-full">
                        {selectedCustomer && (
                          <div>
                            <div className="grid w-full grid-cols-3 text-left">
                              <span className="col-span-1 mr-2">Name: </span>
                              <span className="col-span-2 text-right w-fit">
                                {selectedCustomer.contactPerson}
                              </span>
                            </div>
                            <div className="grid float-left w-full grid-cols-3 text-left">
                              <span className="col-span-1 mr-2">
                                Phone Number:
                              </span>
                              <span className="col-span-2 text-right w-fit">
                                {selectedCustomer.phoneNumber}
                              </span>
                            </div>
                            <div className="grid w-full grid-cols-3 text-left">
                              <span className="col-span-1 mr-2">Address:</span>
                              <span className="col-span-2 text-right w-fit">
                                {getFullAddress(selectedCustomer.address)}
                              </span>
                            </div>
                            <div className="grid w-full grid-cols-3 text-left">
                              <span className="col-span-1 mr-2">
                                Invoice Address:
                              </span>
                              <span className="col-span-2 text-right w-fit">
                                {getFullAddress(
                                  selectedCustomer.invoiceAddress
                                )}
                              </span>
                            </div>
                          </div>
                        )}

                        <div className="grid w-full grid-cols-3 text-left">
                          <span className="col-span-1 mr-2">Discount:</span>
                          <div className="inline-flex m-auto border h-[2rem] min-w-[7rem]">
                            <button onClick={() => setDiscount("")}>
                              <span className="mx-1 my-auto">{"\u20AC"}</span>
                              <NumberField
                                className="min-w-42 text-center max-w-[10vh] max-h-fit"
                                value={discount}
                                handleOnChange={(e: any) => {
                                  setDiscount(
                                    Number(
                                      e.target.value == "" ? 0 : e.target.value
                                    )
                                  );
                                }}
                                alignText
                              />
                            </button>
                          </div>
                        </div>
                        {!openWeightModal && (
                          <div className="grid w-full grid-cols-4 my-2 text-left">
                            <span className="col-span-1 mr-2">
                              Number of Packages:
                            </span>
                            <div className="inline-flex m-auto border h-[2rem] min-w-[7rem] col-span-1">
                              <button onClick={() => setNumberOfPackages("")}>
                                <NumberField
                                  className="min-w-42 text-center max-w-[10vh] max-h-fit"
                                  value={numberOfPackages?.toString() ?? ""}
                                  handleOnChange={(e: any) => {
                                    setNumberOfPackages(
                                      Number(
                                        e.target.value == ""
                                          ? 0
                                          : e.target.value
                                      )
                                    );
                                  }}
                                  alignText
                                />
                              </button>
                            </div>
                            <span className="col-span-1 mr-2">Weight:</span>
                            <div className="row-flex m-auto h-[2rem] min-w-[7rem] col-span-1">
                              {Array(numberOfPackages)
                                .fill(0)
                                .map((e, i) => (
                                  <button
                                    onClick={(e) => {
                                      weights[i] = 0;
                                      setWeights([...weights]);
                                    }}
                                    className="my-1 border"
                                    key={i}
                                  >
                                    <NumberField
                                      className="min-w-42 text-center max-w-[8vh] max-h-fit"
                                      value={weights[i]?.toString() ?? "0"}
                                      handleOnChange={(e: any) => {
                                        weights[i] = Number(e.target.value);
                                        weights.splice(
                                          Number(numberOfPackages)
                                        );
                                        if (
                                          Number(numberOfPackages) >
                                          weights.length
                                        ) {
                                          setWeights([
                                            ...weights,
                                            ...Array(
                                              Number(numberOfPackages) -
                                                weights.length
                                            ).fill(0),
                                          ]);
                                        } else {
                                          setWeights([...weights]);
                                        }
                                      }}
                                      alignText
                                    />
                                    <span className="my-auto mr-2">{"kg"}</span>
                                  </button>
                                ))}
                            </div>
                          </div>
                        )}
                        {selectedProducts.length > 0 && (
                          <div className="grid w-full grid-cols-3 text-left">
                            <span className="col-span-1 mr-2">Total:</span>
                            <span className="col-span-2 text-right w-fit">
                              {"\u20AC" +
                                (
                                  selectedProducts.reduce(
                                    (prev, curr) =>
                                      curr?.businessPrice * curr?.qty + prev,
                                    Number(shippingCost) - Number(discount)
                                  ) * 1.19
                                ).toFixed(2)}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <button
                  className="px-4 py-2 rounded-lg bg-tertiary text-primary"
                  onClick={() => {
                    const items = selectedProducts
                      .filter(({ qty }) => qty > 0)
                      .map((product) => ({
                        name: product.name,
                        quantity: product.qty,
                        unit_amount: {
                          currency_code: "EUR",
                          value: (
                            Math.round(product.businessPrice * 100) / 100
                          ).toFixed(2),
                        },
                        tax: {
                          currency_code: "EUR",
                          value: (
                            Math.round(product.businessPrice * 0.19 * 100) / 100
                          ).toFixed(2),
                        },
                      }));
                    const items_total = selectedProducts
                      .reduce(
                        (prev, curr) =>
                          (Math.round(curr.businessPrice * 100) / 100) *
                            curr.qty +
                          prev,
                        0
                      )
                      .toFixed(2);
                    const tax_total = selectedProducts
                      .reduce(
                        (prev, curr) =>
                          (Math.round(curr?.businessPrice * 0.19 * 100) / 100) *
                            curr?.qty +
                          prev,
                        (Number(shippingCost) - Number(discount)) * 0.19
                      )
                      .toFixed(2);
                    const total =
                      (Number(items_total) +
                        Number(shippingCost) -
                        Number(discount)) *
                      1.19;
                    const order: any = {
                      customerInfo: selectedCustomer,
                      createShipment,
                      isSample,
                      shippedFrom: availableLocations.find(
                        ({ name }) => name === shippedFrom
                      ),
                      amount: {
                        breakdown: {
                          items_total: {
                            currency_code: "EUR",
                            value: items_total,
                          },
                          shipping: {
                            currency_code: "EUR",
                            value: Number(shippingCost).toFixed(2),
                          },
                          tax_total: {
                            currency_code: "EUR",
                            value: tax_total,
                          },
                          discount: {},
                        },
                        currency_code: "EUR",
                        value: total.toFixed(2),
                      },
                      items,
                      weights,
                      deliveryDate: deliveryDate ?? null,
                    };
                    if (Number(discount) > 0) {
                      order.amount.breakdown.discount = {
                        currency_code: "EUR",
                        value: Number(discount).toFixed(2),
                      };
                    }

                    if (isValidOrder(order)) {
                      setCreateShipment(false);
                      setIsSample(false);
                      setSelectedCustomer("");
                      setShippedFrom("");
                      setSelectedProducts([]);
                      setShippingCost(10);
                      setDiscount(0);
                      setNumberOfPackages(1);
                      setWeights([]);
                      createBusinessOrder(order).then(() => {
                        getAllOrders().then((res) => {
                          setAllOrders([...res]);
                        });
                      });
                    } else alert("Error: Invalid Input");
                  }}
                >
                  Create Order
                </button>
              </div>
            </AccordionDetails>
          </Accordion>
        </div>
        <div className="flex w-full my-2 h-fit xl:w-11/12 xl:mx-12">
          <ExcelDownloadButton
            rows={rowsForExcel}
            fileName="orders"
            useOriginalKeyWithoutReformat
          />
        </div>
        <button
          className="my-2"
          onClick={() => {
            getAllOrders().then((res) => {
              setAllOrders([...res]);
            });
          }}
        >
          Refresh
        </button>
        {allOrders.length > 0 && (
          <div className="w-full h-full mt-4 xl:w-11/12 xl:mx-12">
            <DataGrid
              rows={
                rows
                //  allOrders.map((order) => ({
                //  id: order.id,
                //  created: getDateString(order.created),
                //  lastUpdated: order.deliveryDate
                //    ? getDateString(order.deliveryDate)
                //    : order.shippedDate
                //    ? getDateString(order.shippedDate)
                //    : "",
                //  completedDate: order.completedDate,
                //  //status: order?.status ?? "Ordered",
                //  type: order?.B2B ? "B2B" : "B2C",
                //  customerName: order.customerInfo.name,
                //  customerAddress: getFullAddress(order.customerInfo.address),
                //  customerPhoneNumber: order.customerInfo.phoneNumber,
                //  items: order.items
                //    .map((item: any) => `${item.name} (${item.quantity})`)
                //    .join(", "),
                //  amount: order.amount?.value,
                //}))
              }
              columns={columns}
              rowSelection={false}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
                sorting: { sortModel: [{ field: "created", sort: "desc" }] },
              }}
              pageSizeOptions={[5, 10, 25, 50]}
              //sortingOrder={["desc"]}
              //sortModel={[{ field: "created", sort: "desc" }]}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default Orders;
