import { createSlice } from "@reduxjs/toolkit";

type State = { id: string; qty: number };

const cartSlice = createSlice({
  name: "cart",
  initialState: [],
  reducers: {
    addNewProduct(state: any, action: any) {
      const productInCart = state.find(
        ({ id }: any) => id == action.payload.id
      );
      if (productInCart) productInCart.qty += action.payload.qty;
      //  state = [...state, { id: action.payload, qty: 1 }];
      else
        state.push({
          id: action.payload.id,
          qty: action.payload.qty,
        });
    },
    addOne(state: State[], action: any) {
      const productToUpdate = state.find(({ id }) => action.payload == id);
      if (productToUpdate) {
        if (productToUpdate.qty) productToUpdate.qty += 1;
        else productToUpdate.qty = 1;
      }
    },
    substractOne(state: State[], action: any) {
      const productToUpdate = state.find(({ id }) => action.payload == id);
      if (productToUpdate) {
        if (productToUpdate.qty) productToUpdate.qty -= 1;
        else productToUpdate.qty = 0;
        if (productToUpdate.qty < 0) productToUpdate.qty = 0;
        if (productToUpdate?.qty == 0) {
          const index = state.findIndex(({ id }) => id === action.payload);
          state.splice(index, 1);
        }
      }
    },
    defineQuantity(state: State[], action: any) {
      const productToUpdate = state.find(
        ({ id }) => action.payload.product == id
      );
      if (productToUpdate) {
        productToUpdate.qty = action.payload.qty;
      }
    },
    removeProduct(state: State[], action: any) {
      const index = state.findIndex(({ id }) => id === action.payload);
      state.splice(index, 1);
    },
    clearCart(state: any) {
      state = [];
      return state;
    },
    setCartFromStorage(state: State[], action: any) {
      return action.payload;
    },
  },
});

export const {
  addNewProduct,
  addOne,
  substractOne,
  clearCart,
  removeProduct,
  defineQuantity,
} = cartSlice.actions;
export default cartSlice.reducer;
