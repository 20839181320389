import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import { BarChart } from "@mui/x-charts";
import { FormControlLabel } from "@mui/material";
import { Order } from "types/order";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import ReactDatePicker from "react-datepicker";
import { getAllOrders } from "firestore/orders";
import { useState } from "react";

export const Dashboard = ({
  allOrders,
  allCustomers,
  allProducts,
  setAllOrders,
}: {
  allOrders: any[];
  allCustomers: any[];
  allProducts: any[];
  setAllOrders: any;
}) => {
  const now = new Date();
  const aYearAgo = new Date(new Date().setFullYear(now.getFullYear() - 1));
  const [graphByCustomer, setGraphByCustomer] = useState("total");
  const [fromDate, setFromDate] = useState<Date>(aYearAgo);
  const [toDate, setToDate] = useState<Date>(now);

  const getDatasetByCustomers = (customers: any, orders: Order[]) => {
    const uniqueDates: string[] = [];
    const dataset: any[] = [];
    orders
      .sort((a, b) => a.created.toMillis() - b.created.toMillis())
      .forEach((order) => {
        const orderTotal = order.items.reduce(
          (prev, curr) =>
            curr?.quantity *
              (Number(curr?.tax?.value) + Number(curr?.unit_amount?.value)) +
            prev,
          0
        );
        const { created } = order;
        const month = created.toDate().getMonth() + 1;
        const year = created.toDate().getFullYear();
        const yymm = `${year}/${month}`;
        if (
          orderTotal > 0 &&
          created.toDate() > fromDate &&
          created.toDate() < toDate
        ) {
          const customer = customers.find(
            (customer: any) => order.customerInfo.name == customer.name
          );
          const customerName = order.B2B
            ? customer?.shortName ?? customer?.name
            : "B2C";
          const existingIndex = dataset.findIndex(
            ({ name }) => name == customerName
          );
          if (existingIndex >= 0) {
            dataset[existingIndex].total += orderTotal;
            dataset[existingIndex].orderCount += 1;
            dataset[existingIndex].averageOrderTotal =
              dataset[existingIndex].total / dataset[existingIndex].orderCount;
            if (
              !Object.keys(dataset[existingIndex]).includes(`${yymm}-count`)
            ) {
              dataset[existingIndex][`${yymm}-count`] = 1;
            } else {
              dataset[existingIndex][`${yymm}-count`] += 1;
            }
            if (
              !Object.keys(dataset[existingIndex]).includes(`${yymm}-total`)
            ) {
              dataset[existingIndex][`${yymm}-total`] = orderTotal;
            } else {
              dataset[existingIndex][`${yymm}-total`] += orderTotal;
            }
            dataset[existingIndex][`${yymm}-averageOrder`] =
              dataset[existingIndex][`${yymm}-total`] /
              dataset[existingIndex][`${yymm}-count`];
          } else {
            dataset.push({
              name: customerName,
              total: orderTotal,
              orderCount: 1,
              averageOrderTotal: orderTotal,
              [`${yymm}-total`]: orderTotal,
              [`${yymm}-count`]: 1,
              [`${yymm}-averageOrder`]: orderTotal,
            });
          }
          if (!uniqueDates.includes(yymm)) uniqueDates.push(yymm);
        }
      });
    return { dataset: dataset.sort((a, b) => b.total - a.total), uniqueDates };
  };

  const getDataset = (customers: any, orders: Order[]) => {
    const dataset: {
      month: number;
      year: number;
      yymm: string;
      total: number;
      monthDate: Date;
      [key: string]: number | string | Date;
    }[] = [];
    const customerNames: string[] = [];
    const monthDates = [];
    let tempDate = new Date(fromDate);
    while (tempDate <= toDate) {
      monthDates.push(new Date(tempDate.getTime()));
      tempDate.setMonth(tempDate.getMonth() + 1);
    }
    monthDates.forEach((monthDate) => {
      const uniqueCustomerNames: string[] = [];
      const month = monthDate.getMonth() + 1;
      const year = monthDate.getFullYear();
      dataset.push({
        month,
        year,
        yymm: `${year}/${month}`,
        total: 0,
        monthDate,
      });
      orders
        .filter(
          (order) =>
            order.created.toDate().getMonth() + 1 == month &&
            order.created.toDate().getFullYear() == year
        )
        .forEach((order) => {
          const orderTotal = order.items.reduce(
            (prev, curr) =>
              curr?.quantity *
                (Number(curr?.tax?.value) + Number(curr?.unit_amount?.value)) +
              prev,
            0
          );
          const currentData = dataset.find(
            (e) => e.month == month && e.year == year
          );
          if (
            orderTotal > 0 &&
            currentData &&
            order.created.toDate() > fromDate &&
            order.created.toDate() < toDate
          ) {
            const customer = customers.find(
              (customer: any) => order.customerInfo.name == customer.name
            );
            const customerName: string = order?.B2B
              ? customer?.shortName ?? customer?.name
              : "B2C";

            currentData.total += orderTotal;
            if (uniqueCustomerNames.includes(customerName))
              currentData[customerName] += orderTotal;
            else {
              currentData[customerName] = orderTotal;
              uniqueCustomerNames.push(customerName);
            }
            if (!customerNames.includes(customerName))
              customerNames.push(customerName);
          }
        });
    });
    return {
      dataset,
      customerNames,
    };
  };

  const getDatasetByProducts = (products: any[], orders: Order[]) => {
    const dataset: any[] = products.map(({ name }) => ({
      total: 0,
      name,
      count: 0,
    }));
    orders
      .filter(({ isSample }) => !isSample)
      .forEach((order) => {
        const { created } = order;
        const month = created.toDate().getMonth() + 1;
        const year = created.toDate().getFullYear();
        const yymm = `${year}/${month}`;
        const customerName: string = order?.B2B
          ? order?.customerInfo?.name
          : "B2C";

        order.items.forEach((item) => {
          const currentDataset = dataset.find(({ name }) => name == item.name);
          currentDataset.count += item?.quantity;
          currentDataset.total +=
            Number(item?.quantity) *
            (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          if (!Object.keys(currentDataset).includes(`${yymm}-count`)) {
            currentDataset[`${yymm}-count`] = Number(item?.quantity);
          } else {
            currentDataset[`${yymm}-count`] += Number(item?.quantity);
          }

          if (!Object.keys(currentDataset).includes(`${yymm}-total`)) {
            currentDataset[`${yymm}-total`] =
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          } else {
            currentDataset[`${yymm}-total`] +=
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          }

          if (!Object.keys(currentDataset).includes(`${customerName}-count`)) {
            currentDataset[`${customerName}-count`] = Number(item?.quantity);
          } else {
            currentDataset[`${customerName}-count`] += Number(item?.quantity);
          }

          if (!Object.keys(currentDataset).includes(`${customerName}-total`)) {
            currentDataset[`${customerName}-total`] =
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          } else {
            currentDataset[`${customerName}-total`] +=
              Number(item?.quantity) *
              (Number(item?.tax?.value) + Number(item?.unit_amount?.value));
          }
        });
      });
    return dataset;
  };

  return (
    <div className="relative flex w-full h-full min-h-screen mx-auto">
      <div className="w-full h-full mx-auto">
        <h3 className="w-full mb-8 text-2xl font-semibold">Dashboard</h3>
        <button
          className="my-2"
          onClick={() => {
            getAllOrders().then((res) => {
              setAllOrders([...res]);
            });
          }}
        >
          Refresh
        </button>
        {allOrders.length > 0 && (
          <div>
            <div className="overflow-y-scroll w-full max-h-[800px] mt-4 xl:w-11/12 xl:mx-12">
              <div className="inline-block w-full my-2">
                <div className="flex float-left space-x-4 w-fit">
                  <span>From:</span>
                  <ReactDatePicker
                    selected={fromDate}
                    onChange={(date: Date) => setFromDate(date)}
                  />
                  <span>To:</span>
                  <ReactDatePicker
                    selected={toDate}
                    onChange={(date: Date) => setToDate(date)}
                  />
                </div>
                <RadioGroup
                  className="float-right"
                  row
                  value={graphByCustomer}
                  onChange={(e) => setGraphByCustomer(e.target.value)}
                >
                  <FormControlLabel
                    value="total"
                    control={<Radio size="small" />}
                    label="Total Order"
                  />
                  <FormControlLabel
                    value="averageOrderTotal"
                    control={<Radio size="small" />}
                    label="Average Per 
              Order"
                  />
                  <FormControlLabel
                    value="count"
                    control={<Radio size="small" />}
                    label="Order Count"
                  />
                </RadioGroup>
              </div>
              <BarChart
                dataset={getDatasetByCustomers(allCustomers, allOrders).dataset}
                layout="horizontal"
                height={Math.max(
                  500,
                  getDatasetByCustomers(allCustomers, allOrders).dataset
                    .length * 20
                )}
                margin={{ left: 200 }}
                yAxis={[
                  {
                    scaleType: "band",
                    dataKey: "name",
                  },
                ]}
                series={
                  graphByCustomer == "averageOrderTotal"
                    ? [
                        {
                          dataKey: "averageOrderTotal",
                          valueFormatter: (value) =>
                            `${Math.round(
                              Number(value)
                            ).toLocaleString()}\u20AC`,
                        },
                      ]
                    : getDatasetByCustomers(
                        allCustomers,
                        allOrders
                      ).uniqueDates.map((e, i) => ({
                        dataKey: `${e}-${graphByCustomer}`,
                        stack: "stack",
                        label: e,
                        color: `hsl(${20 * i}, 40%, 50%)`,
                        valueFormatter: (value) =>
                          graphByCustomer.includes("total")
                            ? `${Math.round(
                                Number(value)
                              ).toLocaleString()}\u20AC`
                            : value?.toString() ?? "0",
                      }))
                }
                tooltip={{
                  trigger:
                    graphByCustomer == "averageOrderTotal" ? "axis" : "item",
                }}
                grid={{ vertical: true }}
              />
            </div>
            <div className="overflow-y-scroll w-full max-h-[1200px] mt-4 xl:w-11/12 xl:mx-12">
              <div className="flex float-left space-x-4 w-fit">
                <span>From:</span>
                <ReactDatePicker
                  selected={fromDate}
                  onChange={(date: Date) => setFromDate(date)}
                />
                <span>To:</span>
                <ReactDatePicker
                  selected={toDate}
                  onChange={(date: Date) => setToDate(date)}
                />
              </div>
              <BarChart
                dataset={getDataset(allCustomers, allOrders).dataset}
                layout="vertical"
                height={500}
                margin={{ left: 100, bottom: 100 }}
                xAxis={[
                  {
                    scaleType: "band",
                    dataKey: "yymm",
                  },
                ]}
                //series={[{ dataKey: "orderTotal" }]}
                //series={[{ dataKey: "total" }]}
                series={getDataset(allCustomers, allOrders).customerNames.map(
                  (e, i) => ({
                    dataKey: e,
                    stack: "stack",
                    label: e,
                    color: `hsl(${20 * i}, 40%, 50%)`,
                    valueFormatter: (value) =>
                      `${Math.round(Number(value)).toString()}\u20AC`,
                  })
                )}
                slotProps={{
                  legend: {
                    hidden: true,
                  },
                }}
                tooltip={{
                  trigger: "item",
                }}
                grid={{ vertical: true }}
              />
            </div>
            <div className="overflow-y-scroll w-full max-h-[1200px] mt-4 xl:w-11/12 xl:mx-12">
              <div className="flex float-left space-x-4 w-fit">
                <span>From:</span>
                <ReactDatePicker
                  selected={fromDate}
                  onChange={(date: Date) => setFromDate(date)}
                />
                <span>To:</span>
                <ReactDatePicker
                  selected={toDate}
                  onChange={(date: Date) => setToDate(date)}
                />
              </div>
              <BarChart
                dataset={getDatasetByProducts(allProducts, allOrders)}
                layout="horizontal"
                height={500}
                margin={{ left: 100, bottom: 200 }}
                yAxis={[
                  {
                    scaleType: "band",
                    dataKey: "name",
                  },
                ]}
                //series={[{ dataKey: "orderTotal" }]}
                series={[{ dataKey: "total" }]}
                //series={allProducts.map((e, i) => ({
                //  dataKey: `${e.name}-count`,
                //  stack: "stack",
                //  label: e,
                //  color: `hsl(${20 * i}, 40%, 50%)`,
                //  valueFormatter: (value) =>
                //    `${Math.round(Number(value)).toString()}\u20AC`,
                //}))}
                slotProps={{
                  legend: {
                    direction: "row",
                    position: {
                      vertical: "bottom",
                      horizontal: "middle",
                    },
                  },
                }}
                tooltip={{
                  trigger: "item",
                }}
                grid={{ vertical: true }}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Dashboard;
