import NotificationContent from "components/NotificationContent";
import { ProductDetailProps } from "assets/Content";
import { Store } from "react-notifications-component";
import { getPerLiterPrice } from "utils/helper";
import { store } from "store";
import { useNavigate } from "react-router-dom";

export const ProductCards = ({ products }: any) => {
  const navigate = useNavigate();
  const addProduct = (productId: string) => {
    store.dispatch({
      type: "cart/addNewProduct",
      payload: { id: productId, qty: 1 },
    });
    const productName = products.filter(({ id }: any) => id == productId)[0]
      .name;
    Store.addNotification({
      content: NotificationContent(productName, 1),
      //message: "Product Added",
      type: "default",
      //insert: "top",
      container: "bottom-center",
      animationIn: ["animate__animated", "animate__fadeIn"],
      animationOut: ["animate__animated", "animate__fadeOut"],
      dismiss: {
        duration: 2000,
      },
    });
  };
  return (
    <div className="w-full h-fit mb-72">
      <div className="w-full mx-auto h-fit lg:w-11/12">
        <div className="grid grid-cols-2 h-11/12 gap-x-4 gap-y-8 md:gap-y-12 md:grid-cols-3 lg:grid-cols-4">
          {products
            .sort((a: ProductDetailProps, b: ProductDetailProps) =>
              Number(a.id) > Number(b.id) ? 1 : -1
            )
            .map((item: ProductDetailProps) => {
              return (
                <div className="block w-full h-full col-span-1" key={item.id}>
                  <div className="relative inline-block w-full h-full mx-auto max-h-[50vw] md:max-h-[30vw] lg:max-h-[25vw]">
                    <img
                      className="block object-cover w-full h-full mx-auto rounded-xl"
                      src={item.images[0]}
                      alt={item.name}
                      draggable={false}
                    />
                    <div
                      className="absolute top-0 left-0 flex w-full h-full rounded-lg opacity-0 hover:opacity-100"
                      style={{ background: "rgba(0,0,0,0.5" }}
                    >
                      <div className="z-30 flex flex-col w-full h-full m-auto space-y-4 text-white sm:space-y-8">
                        <div className="w-full m-auto space-y-2 h-1/3 sm:h-1/2">
                          <h3 className="w-full mx-auto mt-4 text-lg sm:mt-8 font-akrobat">
                            {item.name}
                          </h3>
                          {item.nameKor && (
                            <h3 className="w-full mx-auto text-lg font-akrobat">
                              {item.nameKor}
                            </h3>
                          )}
                        </div>
                        <div className="w-full h-full my-auto space-y-2">
                          <button
                            className="block w-32 px-4 py-2 mx-auto mt-4 text-sm sm:mt-12 md:mt-4 lg:mt-8 sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover"
                            onClick={() => navigate(`/product/${item.id}`)}
                          >
                            View Product
                          </button>
                          <button
                            className="block w-32 px-4 py-2 mx-auto text-sm sm:text-md rounded-2xl bg-tertiary text-secondary font-akrobat hover:bg-tertiaryhover"
                            onClick={() => addProduct(item.id.toString())}
                          >
                            {item.available ? (
                              <span>Add to Cart</span>
                            ) : (
                              <span>Pre-Order</span>
                            )}
                          </button>
                        </div>
                      </div>
                    </div>
                    {/*</a>*/}
                  </div>
                  <div className="relative inline-block mx-auto h-fit w-fit">
                    <span className="block text-2xl font-akrobat">
                      {item.name}
                    </span>
                    <div className="inline-block">
                      <span className="mx-auto text-xl wfull ">
                        {item.price.toFixed(2).replace(".", ",") + "\u20AC"}
                      </span>
                      {item?.prevPrice ? (
                        <span className="absolute pt-1 pl-2 text-gray-500 line-through align-middle w-fit h-fit text-md">
                          {item?.prevPrice?.toFixed(2).replace(".", ",") +
                            "\u20AC"}
                        </span>
                      ) : (
                        <span></span>
                      )}
                    </div>
                    <span className="block mx-auto text-sm w-fit">
                      {getPerLiterPrice(
                        item.price,
                        item.productInfo?.productVolumeML
                      )}
                    </span>
                    <span className="block mx-auto text-sm w-fit">
                      {item?.productInfo?.alcoholPercent?.toString() &&
                        item?.productInfo?.productVolumeML?.toString() &&
                        `Alcohol ${item.productInfo.alcoholPercent}% | 
                        ${item.productInfo.productVolumeML}mL`}
                    </span>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
};

export default ProductCards;
