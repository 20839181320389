import { ChangeEventHandler } from "react";
import { TextField } from "@mui/material";
import { isValidPhoneNumber } from "utils/helper";

export const PhoneNumberField = ({
  value = "",
  label = "Phone Number",
  handleOnChange,
  className,
}: {
  value: string;
  label?: string;
  handleOnChange?: ChangeEventHandler;
  className?: string;
  disabled?: boolean;
}) => {
  return (
    <div className="inline-flex w-full my-1">
      <span className="w-1/3 my-auto text-left">{label}</span>
      <TextField
        className={className ?? "w-2/3 h-full"}
        type="tel"
        size="small"
        value={value}
        onChange={handleOnChange}
        error={!isValidPhoneNumber(value) && value.length > 0}
      />
    </div>
  );
};

export default PhoneNumberField;
