import { ChangeEventHandler } from "react";
import { TextField } from "@mui/material";

export const NumberField = ({
  value,
  handleOnChange,
  disabled,
  className,
  showBorder,
  alignText,
}: {
  value?: string | number;
  handleOnChange?: ChangeEventHandler;
  disabled?: boolean;
  className?: string;
  showBorder?: boolean;
  alignText?: boolean;
}) => {
  return (
    <TextField
      className={className}
      type="number"
      size="small"
      value={value}
      onChange={handleOnChange}
      sx={{
        "input::-webkit-outer-spin-button,input::-webkit-inner-spin-button": {
          "-webkit-appearance": "none",
          margin: "0",
        },
        "input[type=number]": {
          "-moz-appearance": "textfield",
        },
        "& fieldset": { border: showBorder ? "" : "none" },
      }}
      inputProps={{
        style: alignText ? { textAlign: "center", padding: "4px" } : {},
      }}
      disabled={disabled}
    />
  );
};

export default NumberField;
