import * as XLSX from "xlsx";

import { calculateColumnWidths, formatHeader } from "utils/helper";

export const ExcelDownloadButton = ({
  rows,
  fileName,
  useOriginalKeyWithoutReformat,
}: {
  rows: any[];
  fileName: string;
  useOriginalKeyWithoutReformat?: boolean;
}) => {
  const prepareData = (rows: any[]) => {
    const data = rows.map((row) => {
      const { id, ...rest } = row;
      return rest;
    });
    const headers = useOriginalKeyWithoutReformat
      ? Object.keys(data[0])
      : Object.keys(data[0]).map(formatHeader);
    return { data, headers };
  };

  const handleDownload = () => {
    if (rows.length > 0) {
      const { data, headers } = prepareData(rows);
      const worksheet = XLSX.utils.json_to_sheet(data, {
        header: Object.keys(data[0]),
      });
      XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
      worksheet["!cols"] = calculateColumnWidths(headers, data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Customers");
      const dateString = new Date()
        .toISOString()
        .split("T")[0]
        .replaceAll("-", "");
      XLSX.writeFile(workbook, `${fileName}_${dateString}.xlsx`);
    } else {
      alert("Data Not Available");
    }
  };
  return (
    <button
      className="h-12 px-4 py-2 my-4 ml-auto rounded-lg bg-tertiary text-primary"
      onClick={handleDownload}
    >
      Download Excel
    </button>
  );
};
