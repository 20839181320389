import { useEffect, useRef, useState } from "react";

import { Modal } from "@mui/material";
import { NavLink } from "react-router-dom";
import { getShippingInfo } from "firestore/shipping";
import logo from "assets/images/navigation/logo_transparent.png";
import { useNavigate } from "react-router-dom";

export const Navigation = (props: any) => {
  const sidebarNavRef: any = useRef(null);
  const navigate = useNavigate();
  const [shippingModalOpen, setShippingModalOpen] = useState(false);
  const [shippingInfo, setShippingInfo] = useState([]);

  useEffect(() => {
    getShippingInfo().then((res: any) => {
      setShippingInfo(res);
    });
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (
        sidebarNavRef.current &&
        !sidebarNavRef.current.contains(event.target)
      ) {
        props.setIsNavOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarNavRef]);

  return (
    <div ref={sidebarNavRef}>
      <Modal
        open={shippingModalOpen}
        onClose={() => setShippingModalOpen(false)}
        disableAutoFocus={true}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className="w-full max-w-lg m-auto bg-white rounded-lg max-w-screen sm:w-5/6 lg:w-1/2 h-fit"
      >
        <div className="w-full max-h-screen bg-white rounded-lg max-w-screen h-fit">
          <div className="mx-auto text-center h-fit">
            <h3 className="inline-block w-full my-2 text-xl text-center sm:my-8 sm:text-3xl md:text-4xl font-akrobat h-fit">
              Shipping Costs
            </h3>
            <p className="w-5/6 mx-auto mb-2 text-sm text-left h-fit sm:text-md md:text-lg">
              See below for shipping cost for available countries:
            </p>
            <ul className="w-11/12 mx-auto sm:w-3/4">
              {shippingInfo
                .sort((a: any, b: any) => (a.country > b.country ? 1 : -1))
                .map((shipping: any) => (
                  <li
                    key={shipping.country}
                    className="inline-flex w-full text-sm text-left sm:text-md"
                  >
                    <span className="w-[8rem]">{shipping.country}:</span>
                    <span className="w-[3rem]">
                      {`\u20AC${shipping.shippingCost.toFixed(2)}`}
                    </span>
                    {shipping?.minFreeShipping && (
                      <span className="ml-1 text-xs sm:text-sm w-fit">
                        (free shipping over {"\u20AC"}
                        {shipping.minFreeShipping})
                      </span>
                    )}
                  </li>
                ))}
            </ul>
            <div className="inline-flex justify-center w-32 my-8">
              <button
                onClick={() => setShippingModalOpen(false)}
                className="w-full px-6 py-2 m-auto mx-2 text-white bg-black rounded-lg"
              >
                Close
              </button>
            </div>
          </div>
        </div>
      </Modal>
      <nav
        className={`fixed border-b z-50 flex flex-row w-1/3 h-full sm:hidden bg-white text-stone-700 ease-in-out duration-500 transform  ${
          props.isNavOpen ? "translate-x-0" : "-translate-x-full"
        }`}
        translate="yes"
      >
        <div className="flex flex-col w-full h-full my-auto">
          <div className="z-50 justify-center w-full mx-auto">
            <button
              onClick={() => {
                navigate("/");
                props.setIsNavOpen(false);
                props.setShowCart(false);
              }}
              className="w-fit h-fit"
            >
              <img
                src={logo}
                className="object-contain w-[50px] h-full mx-auto my-4 "
              />
            </button>
            {/*<h3 className="m-auto mt-12 text-3xl">Sojuhalle</h3>*/}
          </div>
          {/*</button>*/}
          <ul className="flex flex-col w-full m-auto my-auto h-2/3">
            <li className="m-auto">
              <button
                onClick={() => {
                  props.setIsNavOpen(false);
                }}
              >
                <NavLink to="/">
                  {({ isActive }) =>
                    isActive ? (
                      <span className="mx-3 text-2xl md:text-3xl md:mx-8 text-stone-400">
                        Home
                      </span>
                    ) : (
                      <span className="mx-3 text-2xl md:text-3xl md:mx-8 ">
                        Home
                      </span>
                    )
                  }
                </NavLink>
              </button>
            </li>
            <li className="m-auto">
              <button
                onClick={() => {
                  props.setIsNavOpen(false);
                }}
              >
                <NavLink to="/product">
                  {({ isActive }) =>
                    isActive ? (
                      <span className="mx-3 text-2xl md:text-3xl md:mx-8 text-stone-400">
                        Product
                      </span>
                    ) : (
                      <span className="mx-3 text-2xl md:text-3xl md:mx-8 ">
                        Product
                      </span>
                    )
                  }
                </NavLink>
              </button>
            </li>
            {/*<li className="inline-block m-2 my-auto">
              <button onClick={() => props.setIsNavOpen(false)}>
                <NavLink to="/blog">
                  {({ isActive }) =>
                    isActive ? (
                      <span className="mx-3 text-2xl md:text-3xl md:mx-8 text-stone-400">
                        Blog
                      </span>
                    ) : (
                      <span className="mx-3 text-2xl md:text-3xl md:mx-8 ">
                        Blog
                      </span>
                    )
                  }
                </NavLink>
              </button>
            </li>*/}
            <li className="inline-block m-2 my-auto">
              <span className="mx-3 text-2xl md:text-3xl md:mx-8 ">
                <button
                  onClick={() => {
                    props.setIsNavOpen(false);
                  }}
                >
                  <NavLink to="/contact">
                    {({ isActive }) =>
                      isActive ? (
                        <span className="mx-3 text-2xl md:text-3xl md:mx-8 text-stone-400">
                          Contact
                        </span>
                      ) : (
                        <span className="mx-3 text-2xl md:text-3xl md:mx-8 ">
                          Contact
                        </span>
                      )
                    }
                  </NavLink>
                </button>
              </span>
            </li>
          </ul>
        </div>
      </nav>
      {/*<nav className="fixed z-40 flex flex-row w-full h-24 mt-4 bg-white border-b md:mt-6 text-stone-700 border-stone-300">*/}
      <nav className="fixed z-40 flex flex-row w-full h-24 mt-0 bg-white border-b text-stone-700 border-stone-300">
        {/*<div className="absolute left-0 z-50 w-screen bg-gray-900 md:-top-6 h-fit -top-4">
          <span className="text-sm tracking-wide text-white lg:text-lg lg:tracking-widest h-fit md:text-md md:tracking-wider">
            Now Shipping Available in 20+ EU Countries!
            <button
              className="ml-2 underline w-fit "
              onClick={async () => {
                setShippingModalOpen(true);
              }}
            >
              Click To View Shipping Information
            </button>
          </span>
        </div>*/}
        <div className="z-50 w-24 mx-4 my-auto mt-2">
          <div className="hidden sm:block">
            <button
              onClick={() => {
                navigate("/");
                props.setIsNavOpen(false);
                props.setShowCart(false);
              }}
              className="w-fit h-fit"
            >
              <img src={logo} className="my-auto w-[50px] mt-4" />
            </button>
            {/*<h3 className="w-full m-auto text-3xl ">Sojuhalle</h3>*/}
          </div>
          {/*<button
            className="relative z-50 block m-4 mt-8 sm:mt-4 sm:hidden"
            onClick={() => {
              props.setIsNavOpen(true);
              props.setShowCart(false);
            }}
          >*/}
          <button
            className="relative z-50 block m-4 mt-4 sm:mt-4 sm:hidden"
            onClick={() => {
              props.setIsNavOpen(true);
              props.setShowCart(false);
            }}
          >
            <div className="flex overflow-hidden items-center justify-center  w-[50px] h-[50px] transform transition-all">
              <div className="flex flex-col justify-between w-[20px] h-[20px] transform transition-all duration-300 origin-center overflow-hidden">
                <div className="bg-black h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10"></div>
                <div className="bg-black h-[2px] w-7 rounded transform transition-all duration-300 group-focus:translate-x-10 delay-75"></div>
                <div className="bg-black h-[2px] w-7 transform transition-all duration-300 origin-left group-focus:translate-x-10 delay-150"></div>
              </div>
            </div>
          </button>
        </div>
        <div className="fixed z-40 w-full m-auto mt-3">
          <div className="justify-around inline-block m-auto mt-8 sm:hidden">
            <NavLink to="/" onClick={() => props.setShowCart(false)}>
              {({ isActive }) =>
                isActive && (
                  <span className="inline-block mx-3 text-2xl md:text-3xl md:mx-8 ">
                    Home
                  </span>
                )
              }
            </NavLink>
            <NavLink
              to="/product"
              onClick={() => {
                props.setShowCart(false);
              }}
            >
              {({ isActive }) =>
                isActive && (
                  <span className="mx-3 text-2xl md:text-3xl md:mx-8">
                    Product
                  </span>
                )
              }
            </NavLink>
            {/*<NavLink to="/blog">
              {({ isActive }) =>
                isActive && (
                  <span className="mx-3 text-2xl md:text-3xl md:mx-8">
                    Blog
                  </span>
                )
              }
            </NavLink>*/}
            <NavLink to="/contact" onClick={() => props.setShowCart(false)}>
              {({ isActive }) =>
                isActive && (
                  <span className="mx-3 text-2xl md:text-3xl md:mx-8 ">
                    Contact
                  </span>
                )
              }
            </NavLink>
          </div>
          <ul className="hidden float-right mx-auto mt-8 mr-24 text-xl sm:flex md:text-2xl ">
            <li className="inline-block m-2 my-auto">
              <NavLink
                to="/"
                onClick={() => {
                  props.setShowCart(false);
                }}
              >
                {({ isActive }) =>
                  isActive ? (
                    <span className="mx-3 md:mx-8 text-stone-400">Home</span>
                  ) : (
                    <span className="mx-3 md:mx-8">Home</span>
                  )
                }
              </NavLink>
            </li>
            <li className="inline-block m-2 my-auto">
              <NavLink
                to="/product"
                onClick={() => {
                  props.setShowCart(false);
                }}
              >
                {({ isActive }) =>
                  isActive ? (
                    <span className="mx-3 md:mx-8 text-stone-400">Product</span>
                  ) : (
                    <span className="mx-3 md:mx-8 ">Product</span>
                  )
                }
              </NavLink>
            </li>
            {/*<li className="inline-block m-2 my-auto">
              <NavLink to="/blog">
                {({ isActive }) =>
                  isActive ? (
                    <span className="mx-3 md:mx-8 text-stone-400">Blog</span>
                  ) : (
                    <span className="mx-3 md:mx-8 ">Blog</span>
                  )
                }
              </NavLink>
            </li>*/}
            <li className="inline-block m-2 my-auto">
              <NavLink
                to="/contact"
                onClick={() => {
                  props.setShowCart(false);
                }}
              >
                {({ isActive }) =>
                  isActive ? (
                    <span className="mx-3 md:mx-8 text-stone-400">Contact</span>
                  ) : (
                    <span className="mx-3 md:mx-8">Contact</span>
                  )
                }
              </NavLink>
            </li>
          </ul>
        </div>
      </nav>
    </div>
  );
};

export default Navigation;
