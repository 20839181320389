import {
  and,
  collection,
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  getFirestore,
  query,
  setDoc,
  updateDoc,
  where,
} from "firebase/firestore";

import { app } from "./firestore";

const db = getFirestore(app);
const promotionRef = collection(db, "promotions");

export const validatePromotionCode = async (promotionCode: string) => {
  const q = query(
    promotionRef,
    and(where("code", "==", promotionCode), where("isValid", "==", true))
  );
  const querySnapshot = await getDocs(q);
  const promotions: any = [];
  querySnapshot.forEach(async (document) => {
    promotions.push(document.data());
  });
  return promotions;
};

export const usePromotionCode = async (promotionId: string) => {
  updateDoc(doc(db, "promotions", promotionId), {
    isValid: false,
  });
};
