import { TextField } from "@mui/material";
import { Address } from "types/customer";

export const AddressFields = ({
  address,
  label = "Address",
  setAddress,
}: {
  address: Address;
  label?: string;
  setAddress: React.Dispatch<React.SetStateAction<Address>>;
}) => {
  return (
    <div className="w-full my-1 row-flex">
      <span className="flex w-full my-1">{label}</span>
      <div className="my-1">
        <TextField
          type="string"
          size="small"
          placeholder="Address Line 1 (Street Number/Name)"
          className="flex w-full h-full"
          value={address.address_line_1}
          onChange={(e) =>
            setAddress({
              ...address,
              address_line_1: e.target.value,
            })
          }
        />
      </div>
      <div className="my-1">
        <TextField
          type="string"
          size="small"
          placeholder="Address Line 2 (optional) (Suite/Apt number)"
          className="flex w-full h-full"
          value={address.address_line_2}
          onChange={(e) =>
            setAddress({
              ...address,
              address_line_2: e.target.value,
            })
          }
        />
      </div>
      <div className="flex w-full my-1">
        <TextField
          type="string"
          size="small"
          placeholder="Postal Code"
          className="flex w-1/3 h-full"
          value={address.postal_code}
          onChange={(e) =>
            setAddress({
              ...address,
              postal_code: e.target.value,
            })
          }
        />

        <TextField
          type="string"
          size="small"
          placeholder="City"
          className="flex w-full h-full"
          value={address.admin_area_1}
          onChange={(e) =>
            setAddress({
              ...address,
              admin_area_1: e.target.value,
            })
          }
        />
      </div>
    </div>
  );
};

export default AddressFields;
