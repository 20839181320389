import { getShipping, getSubtotal, getTax, getTotal } from "utils/helper";

import { Buffer } from "buffer";
import { ProductDetailProps } from "assets/Content";
import { sendOrderConfirmEmail } from "emailjs/emailjs";

const clientIdAndSecret = `${process.env.REACT_APP_PAYPAL_CLIENT_ID}:${process.env.REACT_APP_PAYPAL_SECRET}`;
const base64 = Buffer.from(clientIdAndSecret).toString("base64");
const baseUrl = process.env.REACT_APP_PAYPAL_BASE_URL;
const headers = {
  "Content-Type": "application/json",
  Authorization: `Basic ${base64}`,
};
type State = { id: string; qty: number };
type Discount = { amount: number; isPercentage: boolean };

export const createOrder = async (
  address: any,
  items: State[],
  email: string,
  phoneNumber: string,
  country: any,
  products: ProductDetailProps[],
  discount: Discount
) => {
  localStorage.setItem("cart-items", JSON.stringify(items));

  const orderPost = discount.isPercentage
    ? {
        method: "POST",
        headers,
        body: JSON.stringify({
          intent: "CAPTURE",
          purchase_units: [
            {
              custom_id: `${email}&${phoneNumber}`,
              amount: {
                breakdown: {
                  item_total: {
                    currency_code: "EUR",
                    value: (
                      getSubtotal(items, products, discount) -
                      getTax(items, products, discount)
                    ).toFixed(2),
                  },
                  shipping: {
                    currency_code: "EUR",
                    value: getShipping(
                      items,
                      products,
                      discount,
                      country
                    ).toFixed(2),
                  },
                  tax_total: {
                    currency_code: "EUR",
                    value: getTax(items, products, discount).toFixed(2),
                  },
                },
                value: getTotal(items, products, discount, country).toFixed(2),
                currency_code: "EUR",
              },
              shipping: {
                address,
              },
              items: items.map((item) => {
                const product = products.find(
                  ({ id }) => id.toString() == item?.id
                );
                if (product?.name && product?.price)
                  return {
                    name: product.name,
                    quantity: item.qty,
                    unit_amount: {
                      currency_code: "EUR",
                      value: (
                        Math.round(
                          ((product.price * ((100 - discount.amount) / 100)) /
                            1.19) *
                            100
                        ) / 100
                      ).toFixed(2),
                    },
                    tax: {
                      currency_code: "EUR",
                      value: (
                        Math.round(
                          (product.price *
                            ((100 - discount.amount) / 100) *
                            0.19 *
                            100) /
                            1.19
                        ) / 100
                      ).toFixed(2),
                    },
                  };
              }),
            },
          ],
          payment_source: {
            paypal: {
              experience_context: {
                payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
                brand_name: "SOJUHALLE",
                locale: "en-US", // Change
                landing_page: "LOGIN",
                shipping_preference: "SET_PROVIDED_ADDRESS",
                user_action: "PAY_NOW",
                return_url: `${process.env.REACT_APP_BASE_URL}/cart`,
                cancel_url: `${process.env.REACT_APP_BASE_URL}/cart`,
                //return_url: "https://sojuhalle.web.app/cart",
                //cancel_url: "https://sojuhalle.web.app/cart",
              },
            },
          },
        }),
      }
    : {
        method: "POST",
        headers,
        body: JSON.stringify({
          intent: "CAPTURE",
          purchase_units: [
            {
              custom_id: `${email}&${phoneNumber}`,
              amount: {
                breakdown: {
                  item_total: {
                    currency_code: "EUR",
                    value: (
                      getSubtotal(items, products, discount) -
                      getTax(items, products, discount)
                    ).toFixed(2),
                  },
                  shipping: {
                    currency_code: "EUR",
                    value: getShipping(items, products, country).toFixed(2),
                  },
                  tax_total: {
                    currency_code: "EUR",
                    value: getTax(items, products, discount).toFixed(2),
                  },
                  discount: {
                    currency_code: "EUR",
                    value: discount.amount.toFixed(2),
                  },
                },
                value: getTotal(items, products, discount, country).toFixed(2),
                currency_code: "EUR",
              },
              shipping: {
                address,
              },
              items: items.map((item) => {
                const product = products.find(
                  ({ id }) => id.toString() == item?.id
                );
                if (product?.name && product?.price)
                  return {
                    name: product.name,
                    quantity: item.qty,
                    unit_amount: {
                      currency_code: "EUR",
                      value: (product.price / 1.19).toFixed(2),
                    },
                    tax: {
                      currency_code: "EUR",
                      value: (
                        Math.round(
                          (Number(product.price) * 0.19 * 100) / 1.19
                        ) / 100
                      ).toFixed(2),
                    },
                  };
              }),
            },
          ],
          payment_source: {
            paypal: {
              experience_context: {
                payment_method_preference: "IMMEDIATE_PAYMENT_REQUIRED",
                brand_name: "SOJUHALLE",
                locale: "en-US", // Change
                landing_page: "LOGIN",
                shipping_preference: "SET_PROVIDED_ADDRESS",
                user_action: "PAY_NOW",
                return_url: `${process.env.REACT_APP_BASE_URL}/cart`,
                cancel_url: `${process.env.REACT_APP_BASE_URL}/cart`,
              },
            },
          },
        }),
      };

  return fetch(`${baseUrl}/checkout/orders`, orderPost);
};

export const getOrder = async (token: string) => {
  return fetch(`${baseUrl}/checkout/orders/${token}`, {
    method: "GET",
    headers,
  });
};

export const completeOrder = async (
  token: string,
  products: ProductDetailProps[]
) => {
  const order = await getOrder(token)
    .then(async (res: any) => res.json())
    .catch((err) => console.error(err));
  if (order.status === "APPROVED") {
    return await fetch(`${baseUrl}/checkout/orders/${token}/capture`, {
      method: "POST",
      headers,
    })
      .then(async () => {
        const completedOrder = await getOrder(token)
          .then(async (res: any) => res.json())
          .catch((err) => console.error(err));
        await sendOrderConfirmEmail(completedOrder);
        return completedOrder;
      })
      .catch((err) => console.error(err));
  } else return order;
};

export const getUnitPrice = (item: any) =>
  (Number(item.unit_amount.value) + Number(item.tax.value)).toFixed(2) +
  "\u20AC";

export const getPriceForQty = (item: any) =>
  (
    (Number(item.unit_amount.value) + Number(item.tax.value)) *
    Number(item.quantity)
  ).toFixed(2) + "\u20AC";
