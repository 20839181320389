import "react-multi-carousel/lib/styles.css";
import "react-datepicker/dist/react-datepicker.css";

import {
  GoogleAuthProvider,
  User,
  browserLocalPersistence,
  getAuth,
  setPersistence,
  signInWithPopup,
} from "@firebase/auth";
import { Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";

import Customers from "components/admin/Customers";
import Dashboard from "components/admin/Dashboard";
import Inventories from "components/admin/Inventories";
import Orders from "components/admin/Orders";
import { app } from "firestore/firestore";
import { getAllCustomers } from "firestore/customers";
import { getAllLocations } from "firestore/locations";
import { getAllOrders } from "firestore/orders";
import { getAllProducts } from "firestore/products";

export const AdminView = () => {
  const auth = getAuth(app);
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [currentTab, setCurrentTab] = useState<number>(0);
  const [allOrders, setAllOrders] = useState<any[]>([]);
  const [allCustomers, setAllCustomers] = useState<any[]>([]);
  const [allProducts, setAllProducts] = useState<any[]>([]);
  const [allLocations, setAllLocations] = useState<any[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  setPersistence(auth, browserLocalPersistence);

  useEffect(() => {
    setIsLoading(
      allOrders.length == 0 ||
        allCustomers.length == 0 ||
        allProducts.length == 0 ||
        allLocations.length == 0
    );
  }, [allOrders, allCustomers, allProducts, allLocations]);

  const signIn = () => {
    const provider = new GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: "select_account",
    });

    signInWithPopup(auth, provider)
      .then((result) => {
        // This gives you a Google Access Token. You can use it to access the Google API.
        const credential = GoogleAuthProvider.credentialFromResult(result);
        const user = result.user;
        setUser(result.user);
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        const email = error.customData.email;
        const credential = GoogleAuthProvider.credentialFromError(error);
      });
  };

  useEffect(() => {
    auth.onAuthStateChanged(function (user) {
      if (user) {
        setUser(user);
      } else {
      }
    });
  }, [user]);

  useEffect(() => {
    getAllOrders().then((res) => {
      setAllOrders([...res]);
    });
    getAllCustomers().then((res) => {
      setAllCustomers([...res]);
    });
    getAllProducts(true).then((res) => {
      setAllProducts([...res]);
    });
    getAllLocations().then((res) => {
      setAllLocations([...res]);
    });
  }, []);

  return (
    <div className="flex w-full h-full min-h-screen mx-auto">
      <div className="w-full mt-2">
        {auth.currentUser ? (
          <div>
            {isLoading ? (
              <div className="flex h-full">
                <span className="mx-auto my-36">Loading...</span>
              </div>
            ) : (
              <div>
                <Tabs
                  value={currentTab}
                  onChange={(e, tabIndex) => setCurrentTab(tabIndex)}
                >
                  <Tab label="Dashboard" value={0} />
                  <Tab label="Customer" value={1} />
                  <Tab label="Order" value={2} />
                  <Tab label="Products" value={3} />
                </Tabs>
                {currentTab === 0 && (
                  <Dashboard
                    allOrders={allOrders}
                    allCustomers={allCustomers}
                    allProducts={allProducts}
                    setAllOrders={setAllOrders}
                  />
                )}
                {currentTab === 1 && (
                  <Customers
                    allCustomers={allCustomers}
                    setAllCustomers={setAllCustomers}
                  />
                )}
                {currentTab === 2 && (
                  <Orders
                    allOrders={allOrders}
                    allCustomers={allCustomers}
                    allProducts={allProducts}
                    availableLocations={allLocations.filter((e) => !e.isVendor)}
                    setAllOrders={setAllOrders}
                  />
                )}
                {currentTab === 3 && (
                  <Inventories
                    allProducts={allProducts}
                    allLocations={allLocations}
                    setAllProducts={setAllProducts}
                  />
                )}
              </div>
            )}
          </div>
        ) : (
          <button
            className="px-4 py-2 rounded-lg bg-tertiary text-primary"
            onClick={signIn}
          >
            Sign In
          </button>
        )}
      </div>
    </div>
  );
};

export default AdminView;
